export const REACT_APP_SERVER = process.env.REACT_APP_SERVER;
export const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;
export const REACT_APP_LOCAL_HOST = process.env.REACT_APP_LOCAL_HOST;
export const DEV_ENVIRONMENT = Boolean(process.env.REACT_APP_DEV_ENVIRONMENT);
export var API_ACCESS_TOKEN = ''; export const DATE_TIME_CONST = [`day`, `month`, `year`, `hours`, `minutes`];

export const DEFAULT_DATE_TIME_PICKER_FORMATE = { DATE_TIME_CONST };

export const DATE_FORMATE = {
  DDMMYY: 'DD/MM/YYYY',
};

export const AUTH_CONFIG = {
  LOGIN_TYPE_GOOGLE: 'goog',
  LOGIN_TYPE_MICROSOFT: 'msft',
  LOGIN_TYPE_BASIC_AUTH: 'basic-auth',
  LOGIN_TYPE_ADMIN: `admin`,
  LOGIN_TYPE_USER: `user`,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID
};

export const PAYMENT_METHOD = [
  {
    id: '',
    name: 'Select Payment methods'
  }, {
    id: 'cash',
    name: 'Cash'
  }, {
    id: 'upi',
    name: 'UPI'
  }, {
    id: 'google-pay',
    name: 'Google Pay'
  },
  {
    id: 'phone-pay',
    name: 'Phone Pay'
  },
  {
    id: 'paytm',
    name: 'paytm'
  },
  {
    id: 'credit-card',
    name: 'Credit card'
  }, {
    id: 'debit-card',
    name: 'Debit card'
  },
  {
    id: 'net-banking',
    name: 'Net Banking'
  },
  {
    id: 'other',
    name: 'Other'
  },
];

export const COLOR_CODE = {
  GREEN: 'green',
  YELLOW: 'yellow',
  GRAY: 'gray',
  RED: 'red',
  PURPLE: 'purple',
  DEFAULT: 'default',
}

export const Status = {
  PENDING: 'PENDING',
  RECEIVE: 'RECEIVE',
  RETURN: 'RETURN'
}

export const BOOKING_STATUS_ENUM = {
  BOOKED: 'BOOKED',
  DELIVERED: 'DELIVERED',
  RECEIVED: 'RECEIVED'
}

export const BookingStatus = [
  {
    id: '',
    name: 'Select booking status'
  }, {
    id: 'BOOKED',
    name: 'BOOKED'
  },
  {
    id: 'DELIVERED',
    name: 'DELIVERED'
  },
  {
    id: 'RECEIVED',
    name: 'RECEIVED'
  },
  {
    id: 'CANCELLED',
    name: 'CANCELLED'
  }];

export const TransactionCategory = [
  {
    id: '',
    name: 'Select Payment type'
  }, {
    id: 'CASH',
    name: 'CASH'
  },
  {
    id: 'ONLINE',
    name: 'ONLINE'
  },
  {
    id: 'OTHER',
    name: 'OTHER'
  }];

export enum PaymentCategory {
  DEPOSITE = 'DEPOSITE',
  BUSINESS = 'BUSINESS',
  EXPENCE = 'EXPENCE',
}

export const DepositeStatus = [
  {
    id: '',
    name: 'Select deposite status'
  }, {
    id: 'PENDING',
    name: 'PENDING'
  }, {
    id: 'RECEIVE',
    name: 'RECEIVE'
  },
  {
    id: 'RETURN',
    name: 'RETURN'
  }];

export const StockType = [{
  id: 'SINGLE',
  name: 'SINGLE'
},
{
  id: 'BULK',
  name: 'PENDING'
}]

export const ROLES = {
  SUPER_ADMIN: 'super-admin',
  ADMIN_OPERATOR: 'admin-operator',
  END_USER: 'end-user',
  TENANT_OPERATOR: 'tenant-operator',
  TENANT_ADMIN: 'tenant-admin'
};

export const USER_TYPE = {
  TENANT_USER: 't-u',
  ADMIN_USER: 'a-u'
};

export const ADMIN_ROLES = [
  {
    roleName: 'Super Admin',
    id: 'super-admin'
  },
  {
    roleName: 'Admin Operator',
    id: 'admin-operator'
  }
];

export const TENANT_ROLES = [
  {
    roleName: 'Tenant Admin',
    id: 'tenant-admin'
  },
  {
    roleName: 'Tenant Operator',
    id: 'tenant-operator'
  },
  {
    roleName: 'End User',
    id: 'end-user'
  }
];

export const getRole = (roleId) => {
  const roleList = [...ADMIN_ROLES, ...TENANT_ROLES];
  const filter = roleList.filter((role) => {
    return roleId === role.id;
  });
  if (filter.length > 0) {
    return filter[0].roleName;
  }
};

export const REFRESH_TOKEN = `REFRESH_TOKEN `;
export const DECODED_TOKEN = `DECODED_TOKEN `;
export const ACCESS_TOKEN = `ACCESS_TOKEN `;

export const LIST_VIEW = {
  TABLE_VIEW: 'Table',
  CARD_VIEW: 'Card'
};

export const USER_TABLE = 'UserTable';

export const I18N = {
  ADMIN_USER: 'adminusermanagement',
  ASSET_MANAGEMENT: "asset",
  ASSET_GROUP_MANAGEMENT: "asset_group",
  ASSET_TYPE_MANAGEMENT: "asset_type",
  BOOKING_MANAGEMENT: "bookingmanagement",
  COLOR_MANAGEMENT: 'color',
  CHAT_BOT: 'chatbot',
  DEFAULT: 'lang',
  EXPENCE_MANAGEMENT: "expence",
  FIRM_MANAGEMENT: "firm",
  INVOICE_MANAGEMENT: "invoice",
  LOCATION_MANAGEMENT: "location",
  ORGANIZATION: 'organization',
  PATTERN_MANAGEMENT: "pattern",
  PERMISSION_MASTER_MANAGEMENT: "permission_master",
  PERMISSION_MANAGEMENT: "permission",
  REPORT_MANAGEMENT: "report",
  ROLE_MANAGEMENT: "role",
  SIDE_BAR_MENU: 'sidebarmenu',
  SECRET_MANAGEMENT: 'secretmanagement',
  SSH_SERVER_MANAGEMENT: 'sshservermanagement',
  STORE: 'store',
  USER_MANAGEMENT: 'usermanagement',
  SIZE_MANAGEMENT: "size",
  STOCK_TYPE_MANAGEMENT: "stock_type",
};

export const REGEX = {
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NUMBER_DECIMAL:
    /^(?:\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?|0(?:\.\d{1,2})?)$/,
  NUMBER_INTEGER: /^(?:\d*[1-9]\d*|)$/,
  TEXT_ONLY: /^[a-zA-Z ]*$/,
  PASSWORD:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?!.*\s).{8,}$/,
  ALPHA_NUMBERICS: /^[a-zA-Z0-9-_ ]*$/,
  JSON: /^[\],:{}\s]*$|^"(.|\\[\\"/bfnrt])*"$/,
  URL: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})$/
};

export const FIELD_TYPE = {
  TEXT: 'text',
  LABEL: 'label',
  DIVIDER: 'divider',
  RADIO: 'radio',
  EMAIL: 'email',
  SWITCH: 'switch',
  SELECT: 'select',
  REGEX: 'regex',
  FREE_TEXT: 'freetext',
  PASSWORD: 'password',
  CHECKBOX: 'checkbox',
  TEXTAREA: 'textarea',
  NUMBER_ONLY: 'number_only',
  INTEGER_ONLY: 'integer_only',
  ALPHA_NUMBERICS: 'alphanumerics',
  MULTI_SELECT: 'multi-select',
  AUTOCOMPLETE: 'autocomplete',
  CHECKBOX_GROUP: 'checkbox_group',
  DATE_TIME_PiICKER: 'dateTimePicker',
  DATE: 'date'
};

//below const is from seed, we can remove it if it is not breck any functionality

export const HEADER_AUTHORIZATION = 'a64bccc5-1648-46ae-ad78-b0f890f1d6c1';

export const DEVSTAGE_USERPOOL_ID = 'us-west-2_R4zfKO1hy';
export const QASTAGE_USERPOOL_ID = 'us-west-2_R4zfKO1hy';
export const CLINICALUSER_STAGE_USERPOOL_ID = 'us-west-2_VU5TDSRwJ';

export const DEVSTAGE_USERPOOL_WEBCLIENT_ID = '34jp7c8060jmgqu9bnhjptrotc';

export const QASTAGE_USERPOOL_WEBCLIENT_ID = '34jp7c8060jmgqu9bnhjptrotc';

export const CLINICALUSER__USERPOOL_WEBCLIENT_ID = '34jp7c8060jmgqu9bnhjptrotc';

export const LOGIN = import('src/auth/login/Login');
export const ADMIN_LOGIN = import('src/auth/login/Login');

export const ORGANIZATION = import('src/modules/Organization/Organization');

export const ADMIN_MANAGEMENT = import(
  'src/modules/AdminManagement/AdminManagement'
);

export const USER_MANAGEMENT = import(
  'src/modules/UserManagement/UserManagement'
);

export const BOOKING_MANAGEMENT = import(
  'src/modules/BookingManagement/BookingManagement'
);

export const BOOKING_VIEW = import(
  'src/modules/BookingManagement/BookingDetailsView'
);

export const INVOICE_MANAGEMENT = import(
  'src/modules/Invoice/Invoice'
);

export const EXPENCE_MANAGEMENT = import(
  'src/modules/expence/expence'
);

export const ASSET_MANAGEMENT = import(
  'src/modules/Asset/Asset'
);
export const ASSET_SL_MANAGEMENT = import(
  'src/modules/Asset copy/Asset'
);

export const ASSET_DETAILS = import(
  'src/modules/Asset/AssetDetails'
);

export const ASSET_GROUP_MANAGEMENT = import(
  'src/modules/Asset-group/asset-group'
);

export const ASSET_TYPE_MANAGEMENT = import(
  'src/modules/Asset-type/asset-type'
);
export const COLOR_MANAGEMENT = import(
  'src/modules/Color/color'
);

// export const FIRM_MANAGEMENT = import(
//   'src/modules/Firm/firm'
// );

// export const LOCATION_MANAGEMENT = import(
//   'src/modules/Location/location'
// );

// export const PATTERN_MANAGEMENT = import(
//   'src/modules/Pattern/pattern'
// );

// export const PERMISSION_MASTER_MANAGEMENT = import(
//   'src/modules/Permission-master/permission-master'
// );


// export const PERMISSION_MANAGEMENT = import(
//   'src/modules/Permission/permission'
// );


export const REPORT_MANAGEMENT = import(
  'src/modules/Report/Report'
);

// export const ROLE_MANAGEMENT = import(
//   'src/modules/Role/role'
// );


// export const  SIZE_MANAGEMENT = import(
//   'src/modules/Size/size'
// );

// export const  STOCK_TYPE_MANAGEMENT = import(
//   'src/modules/Stock-type/stock_type'
// );

export const ADMIN_OPERATOR_USER_ASSETS = import(
  'src/modules/UserManagement/UserManagement'
);

export const ERROR_MESSAGE = 'Something wrong, contact to admin';

export const SET_PASSWORD = import('src/auth/login/SetPassword');
export const FORGET_PASSWORD = import('src/auth/login/ForgotPassword');
export const ADMIN_FORGET_PASSWORD = import('src/auth/login/ForgotPassword');
export const CHECK_YOUR_EMAIL = import('src/auth/login/CheckYourEmail');

export const ERROR_PAGE = import('src/modules/ErrorPage/ErrorPage');

export const COMPLETED = 'completed';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export enum statusLable {
  COMPLETED = 'completed',
  PENDING = 'pending',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress'
}

export const PASSWORD_STRENGTH_LABEL_BY_SCORE: Record<number, string> = {
  0: 'weak',
  1: 'weak',
  2: 'fair',
  3: 'good',
  4: 'strong'
};

export const MEGABYTE_CONVERTER = 1000000;
export const MESSAGE = 'message';
export const STATUS_404 = '404';

export const accessTokenFunction = (token?: any) => {
  API_ACCESS_TOKEN = token;
};
