import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteAdminAPI,
  getAdminDataAPI,
  updateAdminUserAPI,
  addAdminUserAPI,
  updateAdminUserStatusAPI
} from '../apis/AdminManagementAPI';
import { AdminData } from '../models';
import { RootState } from 'src/store/reducer';

interface AdminManagementState {
  adminData: AdminData[];
  loadingAdminData: boolean;
  isAdminListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
}

const initialState: AdminManagementState = {
  adminData: [],
  loadingAdminData: false,
  isAdminListUpdated: false,
  isError: false,
  errorMessage: ''
};

export const selectAdminManagementState = ({ adminUserData }: RootState) => ({
  adminData: adminUserData?.adminData,
  loadingAdminData: adminUserData?.loadingAdminData,
  isAdminListUpdated: adminUserData?.isAdminListUpdated,
  isError: adminUserData?.isError,
  errorMessage: adminUserData?.errorMessage
});
export const getAdminData = createAsyncThunk(
  'adminManagementData/getAdminData',
  async () => {
    const response = await getAdminDataAPI();
    return response;
  }
);

export const deleteAdmin = createAsyncThunk(
  'adminManagementData/deleteAdmin',
  async (userEmail: any, { rejectWithValue }) => {
    try {
      const response = await deleteAdminAPI(userEmail);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAdminUser = createAsyncThunk(
  'adminUser/addAdminUser',
  async (addminUserPayload: any, { rejectWithValue }) => {
    try {
      return await addAdminUserAPI(addminUserPayload);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAdminData = createAsyncThunk(
  'adminManagementData/updateAdminData',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateAdminUserAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAdminUserStatus = createAsyncThunk(
  'adminManagementData/updateAdminUserStatus',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateAdminUserStatusAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getAdminInsert = (adminList) => {
  return [
    ...adminList.map((entry) => {
      return {
        id: entry.email,
        email: entry.email,
        name: entry.name,
        isEnabled: entry.is_enabled,
        role: {
          id: entry.roles.id,
          roleName: entry.roles.role_name
        },
        tenants: entry.tenants.map((tenantEntry) => {
          return {
            id: tenantEntry.id,
            tenantName: tenantEntry.tenant_name
          };
        })
      };
    })
  ];
};

export const adminManagementSlice = createSlice({
  name: 'adminManagementData',
  initialState,
  reducers: {
    setIsAdminListUpdated: (state: any, action) => {
      state.isAdminListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAdminData.pending, (state) => {
        state.loadingAdminData = true;
      })
      .addCase(getAdminData.fulfilled, (state: any, action) => {
        state.loadingAdminData = false;
        if (action.payload) {
          const adminData = getAdminInsert(action.payload);
          state.adminData = adminData;
        }
      })
      .addCase(getAdminData.rejected, (state) => {
        state.loadingAdminData = false;
      })
      .addCase(addAdminUser.pending, (state) => {
        state.loadingAdminData = true;
      })
      .addCase(addAdminUser.fulfilled, (state: any, action) => {
        state.loadingAdminData = false;
        state.isAdminListUpdated = true;
      })
      .addCase(addAdminUser.rejected, (state, action: any) => {
        state.loadingAdminData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateAdminData.pending, (state) => {
        state.loadingAdminData = true;
      })
      .addCase(updateAdminData.fulfilled, (state: any, action) => {
        state.loadingAdminData = false;
        state.isAdminListUpdated = true;
      })
      .addCase(updateAdminData.rejected, (state, action: any) => {
        state.loadingAdminData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteAdmin.pending, (state) => {
        state.loadingAdminData = true;
      })
      .addCase(deleteAdmin.fulfilled, (state: any, action) => {
        state.loadingAdminData = false;
        state.isAdminListUpdated = true;
      })
      .addCase(deleteAdmin.rejected, (state, action: any) => {
        state.loadingAdminData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateAdminUserStatus.pending, (state) => {
        state.loadingAdminData = true;
      })
      .addCase(updateAdminUserStatus.fulfilled, (state: any, action) => {
        state.loadingAdminData = false;
        state.isAdminListUpdated = true;
      })
      .addCase(updateAdminUserStatus.rejected, (state, action: any) => {
        state.loadingAdminData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      });
  }
});

export const { setIsAdminListUpdated, setIsError } =
  adminManagementSlice.actions;
export default adminManagementSlice.reducer;
